/*Homepage*/
.showinfo {
  font: #000;
  text-align: center;
  padding-top: 25px;
}

.showLink {
  color: #000;
  text-decoration: none;
}

.showLink:hover {
  text-decoration: none;
}

.showPlace {
  font-size: 12pt;
}

.showImg {
  clear: both;
}

#circustext {
  // background-image: url('../img/bgs/bluebg.jpg');
  height: 100%;
  max-width: 100%;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  clip-path: polygon(0 2rem, 100% 5rem, 100% 98%, 0 100%);
}

#circusparagraph {
  max-width: 95ch;
  text-align: center;
  margin-inline: auto;
}

.news {
  padding-top: 25px;
}
/*END Homepage*/


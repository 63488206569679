/* About page */
.castMemberBox {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	transition: all 0.3s ease;
	&:hover {
		transform: translateX(0);
		@media (min-width: 1024px) {
			transform: translateX(-1.2rem);
		}
	}
	.castMember {
		flex: 1;
		display: flex;
		flex-direction: column;
		transition: all 0.3s ease;
		margin: 0.4rem 0.4rem;
		.castImage {
			border-radius: 50%;
			width: 100px;
			height: 100px;
			object-fit: cover;
			object-position: center;
			margin: 0 auto;
			cursor: pointer;
			transition: all 0.3s ease;
		}
		&:hover {
			transform: translateY(-0.5rem);
			transform: translateX(0rem);
			@media (min-width: 1024px) {
				transform: translateX(1.2rem);
			}
			.castImage {
				transform: scale(1.1);
				box-shadow: 0 0 20px 0px #1049d7;
			}
			small {
				transition: all 0.3s ease;
				transform: translateY(0.5rem);
			}
		}
		&:hover ~ .castMember {
			transform: translateX(0rem);
			@media (min-width: 1024px) {
				transform: translateX(2.4rem);
			}
		}
		&.active {
			.castImage {
				transform: scale(1.1);
				box-shadow: 0 0 20px 0px #1049d7;
			}
			small {
				transition: all 0.3s ease;
				transform: translateY(0.3rem);
			}
		}
	}
}
.castAboutBox {
	background: url("../../images/Backgrounds/bluebg.jpg");
	background-position: center;
	background-attachment: fixed;
	background-size: cover;
}

#knotText {
	margin-top: 3rem;
}
/* END About page */

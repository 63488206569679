// Bootstrap variables
$primary: #1049d7;
$secondary: #000;

// Bootstrap import
@import "../../node_modules/bootstrap/scss/bootstrap";

// Custom Imports
@import "./imports/header";
@import "./imports/home";
@import "./imports/about";
@import "./imports/footer";
/*Footer*/
footer {
	padding: 0 0 1rem 0;
	margin-bottom: -0.3rem;
	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	-webkit-background-size: cover;
	background-size: cover;
	clip-path: polygon(0 0, 100% 30%, 100% 100%, 0 100%);
}

footer > #copytext {
	margin: 70px 0 0 50px;
}

#copytext > a {
	color: #fff;
}

.partnerLink {
	color: #fff;
}

#footer {
	padding-top: 50px;
}
/*END Footer*/